import React, { Component, useState, useEffect } from "react"
import SearchBar from "./searchBar"
import { Box } from "@material-ui/core"
import SearchFilter from "./searchFilter"
import { getVisibleGrid } from "../../constants/mapComponent"
import axios from "axios"
import Maps from "../MapSearch/Maps"
const FilterComponent = () => {
  const [data, setData] = useState([])
  const [spinner, setSpinner] = useState(true)
  const [fav, setFav] = useState(false)
  const [open, setOpen] = useState("")
  const [filterRecords, setFilterRecords] = useState([])
  const [filter, setFilter] = useState(false)
  const [mapState, setMapState] = useState({
    lat: "48.845718088014884",
    lng: "-117.64217687325846",
    swlat: "44.444542174504654",
    swlng: "-135.13241124825848",
    nelat: "52.89148636361805",
    nelng: "-100.15194249825846",
    zoom: "5",
    markersvisible: "0",
    totalcells: "21",
    cells:
      "a-14.9-14.8-14.a-13.9-13.8-13.a-12.9-12.8-12.a-11.9-11.8-11.a-10.9-10.8-10.a-j.9-j.8-j.a-i.9-i.8-i",
    baths: "0+",
    beds: "0+",
    built_from: "1900",
    built_to: "2020",
    days_back: "720",
    dollarfoot_from: "0",
    dollarfoot_to: "2000",
    dom: "720",
    features: "",
    frontage: "0",
    keywords: "",
    kitchens: "0+",
    levels: "1+",
    lotsize_from: "0",
    lotsize_to: "43560000",
    media: "",
    openhouse: "false",
    parking: "0",
    price_from: "0",
    price_reduced: "0",
    price_to: "20000000",
    restrictions: "",
    sold: "true",
    sqft_from: "0",
    sqft_to: "10000",
    types: "house,townhouse,apartment",
  })

  const handleFilters = () => {
    setFilter(!filter)
  }

  const handleFav = () => {
    setFav(!fav)
  }

  const handleSearch = data => {
    let obj = { ...mapState }
    setSpinner(true)
    Object.keys(data).forEach(key => {
      switch (key) {
        case "lat":
        case "lng":
        case "nelat":
        case "nelng":
        case "swlat":
        case "swlng":
          obj[key] = data[key].toString()
          break
        default:
      }
    })
    getCells(obj)
  }

  const handleMarker = id => {
    setOpen(id)
  }

  const handleMarkerClose = () => {
    setOpen("")
  }

  const handleBounds = coord => {
    setSpinner(true)
    let obj = { ...mapState }
    Object.keys(coord).forEach(key => {
      obj[key] = coord[key].toString()
    })
    getCells(obj)
  }

  const getCells = obj => {
    let cells = getVisibleGrid(obj)
    obj["totalcells"] = cells.length
    obj["cells"] = cells
    setMapState(obj)
  }

  useEffect(() => {
    let formData = new FormData()
    for (var key in mapState) {
      formData.append(key, mapState[key])
    }
    fetchApi(formData)
  }, [mapState])

  const fetchApi = formData => {
    const token = "s0n8HWXUgIaIGigA9loDqRHVs2G3"
    axios
      .post("https://www.fisherly.com/api/markers", formData, {
        headers: { Authorization: `Basic ${token}` },
      })
      .then(data => {
        setData(data.data.cells)
        setSpinner(false)
      })
      .catch(err => {
        console.log("markers", err)
      })
  }

  const handleChangeFilter = e => {
    const { name, value } = e.target
    setSpinner(true)
    if (
      name === "media" ||
      name === "restrictions" ||
      name === "types" ||
      name === "features" ||
      name === "keywords"
    ) {
      setMapState({ ...mapState, [name]: value.join() })
    } else {
      setMapState({ ...mapState, [name]: value })
    }
  }

  const handleToggle = value => {
    setSpinner(true)
    setMapState({ ...mapState, ["sold"]: value })
  }

  return (
    <Box
      style={{
        padding: 10,
        boxShadow: "0px 5px 5px #ccc",
        overflow: "hidden",
      }}
    >
      <SearchBar
        handleSearch={handleSearch}
        handleToggle={handleToggle}
        handleFilters={handleFilters}
        filter={filter}
      />
      <SearchFilter
        mapState={mapState}
        handleChangeFilter={handleChangeFilter}
        filter={filter}
      />
      <Maps
        id="myMap"
        spinner={spinner}
        handleBounds={handleBounds}
        latitude={mapState.lat}
        longitude={mapState.lng}
        zoom={mapState.zoom}
        handleFav={handleFav}
        fav={fav}
        data={data}
      />
    </Box>
  )
}

export default FilterComponent
