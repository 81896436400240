import React, { Component, useState, Fragment } from "react"
import {
  InputAdornment,
  TextField,
  Button,
  Icon,
  Dialog,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Box,
  FormControlLabel,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import Switch from "@material-ui/core/Switch"
import { green, red } from "@material-ui/core/colors"
import Autocomplete from "@material-ui/lab/Autocomplete"
import axios from "axios"

const SearchBar = ({
  filterValue,
  openFilter,
  handleSearch,
  handleToggle,
  filter,
  handleFilters,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))
  const [activeSold, setActiveSold] = React.useState(true)
  const [data, setData] = useState([])
  const ActiveSoldSwitch = withStyles({
    switchBase: {
      color: "#1d9c5a",
      "&$checked": {
        color: "#db4437",
      },
      "&$checked + $track": {
        backgroundColor: "#db4437",
      },
    },
    checked: {},
    track: {},
  })(Switch)

  const handleActiveSold = event => {
    setActiveSold(event.target.checked)
    handleToggle(event.target.checked)
  }

  const onChangeText = e => {
    const token = "s0n8HWXUgIaIGigA9loDqRHVs2G3"
    axios
      .get(`https://www.fisherly.com/api/places?searchterm=${e.target.value}`, {
        headers: { Authorization: `Basic ${token}` },
      })
      .then(data => {
        setData(data.data.results)
      })
      .catch(err => {
        console.log("markers", err)
      })
  }

  const [toggle, setToggle] = React.useState(true)
  const handleChangeToggle = event => {
    setToggle(!toggle)
  }

  const handleMobileFilter = e => {
    openFilter(true)
  }
  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: "Pulp Fiction", year: 1994 },
    { title: "The Lord of the Rings: The Return of the King", year: 2003 },
    { title: "The Good, the Bad and the Ugly", year: 1966 },
    { title: "Fight Club", year: 1999 },
    { title: "The Lord of the Rings: The Fellowship of the Ring", year: 2001 },
    { title: "Star Wars: Episode V - The Empire Strikes Back", year: 1980 },
    { title: "Forrest Gump", year: 1994 },
    { title: "Inception", year: 2010 },
    { title: "The Lord of the Rings: The Two Towers", year: 2002 },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: "Goodfellas", year: 1990 },
    { title: "The Matrix", year: 1999 },
    { title: "Seven Samurai", year: 1954 },
    { title: "Star Wars: Episode IV - A New Hope", year: 1977 },
    { title: "City of God", year: 2002 },
    { title: "Se7en", year: 1995 },
    { title: "The Silence of the Lambs", year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: "Life Is Beautiful", year: 1997 },
    { title: "The Usual Suspects", year: 1995 },
    { title: "Léon: The Professional", year: 1994 },
    { title: "Spirited Away", year: 2001 },
    { title: "Saving Private Ryan", year: 1998 },
    { title: "Once Upon a Time in the West", year: 1968 },
    { title: "American History X", year: 1998 },
    { title: "Interstellar", year: 2014 },
    { title: "Casablanca", year: 1942 },
    { title: "City Lights", year: 1931 },
    { title: "Psycho", year: 1960 },
    { title: "The Green Mile", year: 1999 },
    { title: "The Intouchables", year: 2011 },
    { title: "Modern Times", year: 1936 },
    { title: "Raiders of the Lost Ark", year: 1981 },
    { title: "Rear Window", year: 1954 },
    { title: "The Pianist", year: 2002 },
    { title: "The Departed", year: 2006 },
    { title: "Terminator 2: Judgment Day", year: 1991 },
    { title: "Back to the Future", year: 1985 },
    { title: "Whiplash", year: 2014 },
    { title: "Gladiator", year: 2000 },
    { title: "Memento", year: 2000 },
    { title: "The Prestige", year: 2006 },
    { title: "The Lion King", year: 1994 },
    { title: "Apocalypse Now", year: 1979 },
    { title: "Alien", year: 1979 },
    { title: "Sunset Boulevard", year: 1950 },
    {
      title:
        "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
      year: 1964,
    },
    { title: "The Great Dictator", year: 1940 },
    { title: "Cinema Paradiso", year: 1988 },
    { title: "The Lives of Others", year: 2006 },
    { title: "Grave of the Fireflies", year: 1988 },
    { title: "Paths of Glory", year: 1957 },
    { title: "Django Unchained", year: 2012 },
    { title: "The Shining", year: 1980 },
    { title: "WALL·E", year: 2008 },
    { title: "American Beauty", year: 1999 },
    { title: "The Dark Knight Rises", year: 2012 },
    { title: "Princess Mononoke", year: 1997 },
    { title: "Aliens", year: 1986 },
    { title: "Oldboy", year: 2003 },
    { title: "Once Upon a Time in America", year: 1984 },
    { title: "Witness for the Prosecution", year: 1957 },
    { title: "Das Boot", year: 1981 },
    { title: "Citizen Kane", year: 1941 },
    { title: "North by Northwest", year: 1959 },
    { title: "Vertigo", year: 1958 },
    { title: "Star Wars: Episode VI - Return of the Jedi", year: 1983 },
    { title: "Reservoir Dogs", year: 1992 },
    { title: "Braveheart", year: 1995 },
    { title: "M", year: 1931 },
    { title: "Requiem for a Dream", year: 2000 },
    { title: "Amélie", year: 2001 },
    { title: "A Clockwork Orange", year: 1971 },
    { title: "Like Stars on Earth", year: 2007 },
    { title: "Taxi Driver", year: 1976 },
    { title: "Lawrence of Arabia", year: 1962 },
    { title: "Double Indemnity", year: 1944 },
    { title: "Eternal Sunshine of the Spotless Mind", year: 2004 },
    { title: "Amadeus", year: 1984 },
    { title: "To Kill a Mockingbird", year: 1962 },
    { title: "Toy Story 3", year: 2010 },
    { title: "Logan", year: 2017 },
    { title: "Full Metal Jacket", year: 1987 },
    { title: "Dangal", year: 2016 },
    { title: "The Sting", year: 1973 },
    { title: "2001: A Space Odyssey", year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: "Toy Story", year: 1995 },
    { title: "Bicycle Thieves", year: 1948 },
    { title: "The Kid", year: 1921 },
    { title: "Inglourious Basterds", year: 2009 },
    { title: "Snatch", year: 2000 },
    { title: "3 Idiots", year: 2009 },
    { title: "Monty Python and the Holy Grail", year: 1975 },
  ]

  const SearchTextComponent = () => (
    <Autocomplete
      freeSolo
      style={{ width: "200px" }}
      id="free-solo-2-demo"
      disableClearable
      options={data}
      getOptionLabel={option => option.display}
      renderOption={option => (
        <React.Fragment>
          <span>{option.display}</span>
          {option.type}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          // value={search}
          onChange={onChangeText}
          margin="normal"
          variant="standard"
          InputProps={{
            ...params.InputProps,
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )

  const MobileSearchBar = () => (
    <div className={classes.tabContainer}>
      {/* <SearchTextComponent /> */}
      {/* <TextField id="standard-basic" label="Standard" /> */}
      <div style={{ marginLeft: "auto" }}>
        <Button>{<Icon>near_me</Icon>}</Button>
        <Button className={classes.mobileBtn} onClick={handleMobileFilter}>
          Filter
        </Button>
      </div>
    </div>
  )

  const WebSearchBar = () => (
    <div className={classes.tabContainer}>
      {/* <SearchTextComponent /> */}
      {/* <TextField
        id="standard-basic"
        label="search"
        name="search"
        value={search}
        onChange={e => setSearch(e.target.value)}
      /> */}
      <div className={classes.searchButtonTab}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.tabBtn}
          endIcon={<Icon>arrow_drop_up</Icon>}
        >
          Filters
        </Button>
        <Button
          disableRipple
          variant="outlined"
          color="primary"
          className={classes.tabBtn}
        >
          <Typography component="div" className={classes.activeSoldBtn}>
            <Grid component="label" container alignItems="center" spacing={0}>
              <Grid item>Active</Grid>
              <Grid item>
                <ActiveSoldSwitch
                  checked={activeSold}
                  onChange={handleActiveSold}
                  name="sold"
                />
              </Grid>
              <Grid item>Sold</Grid>
            </Grid>
          </Typography>
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.tabBtn}
          endIcon={<Icon>arrow_drop_up</Icon>}
        >
          Filters
        </Button>
        <Button variant="outlined" color="primary" className={classes.tabBtn}>
          {<Icon>near_me</Icon>}
        </Button>
        <Button variant="outlined" color="primary" className={classes.tabBtn}>
          Share
        </Button>
        <Button variant="outlined" color="primary" className={classes.tabBtn}>
          Reset
        </Button>
      </div>
    </div>
  )

  const showLocation = position => {
    var latitude = position.coords.latitude
    var longitude = position.coords.longitude
    console.log("Latitude : " + latitude + " Longitude: " + longitude)
  }

  const errorHandler = err => {
    if (err.code == 1) {
      console.log("Error: Access is denied!")
    } else if (err.code == 2) {
      console.log("Error: Position is unavailable!")
    }
  }

  const handleNearMe = () => {
    if (navigator.geolocation) {
      // timeout at 60000 milliseconds (60 seconds)
      var options = { timeout: 60000 }
      navigator.geolocation.getCurrentPosition(
        showLocation,
        errorHandler,
        options
      )
    } else {
      console.log("Sorry, browser does not support geolocation!")
    }
  }

  return (
    <div className={classes.tabContainer}>
      <Autocomplete
        freeSolo
        style={{ width: "250px" }}
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={option => option.display}
        onChange={(event, value) => handleSearch(value)}
        renderOption={option => (
          <div className={classes.listDown}>
            <div>{option.display}</div>
            <div className={classes.listDownInner}>{option.type}</div>
          </div>
        )}
        renderInput={params => (
          <TextField
            {...params}
            onChange={onChangeText}
            margin="normal"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              type: "search",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <div className={classes.searchButtonTab}>
        <Button
          disableRipple
          variant="outlined"
          color="primary"
          className={classes.tabBtn}
        >
          <Typography component="div" className={classes.activeSoldBtn}>
            <Grid component="label" container alignItems="center" spacing={0}>
              <Grid item>Active</Grid>
              <Grid item>
                <ActiveSoldSwitch
                  checked={activeSold}
                  onChange={handleActiveSold}
                  name="activeSold"
                />
              </Grid>
              <Grid item>Sold</Grid>
            </Grid>
          </Typography>
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.tabBtn}
          endIcon={
            filter ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>
          }
          onClick={() => handleFilters()}
        >
          Filters
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.tabBtn}
          onClick={() => handleNearMe()}
        >
          {<Icon>near_me</Icon>}
        </Button>
        <Button variant="outlined" color="primary" className={classes.tabBtn}>
          Share
        </Button>
        <Button variant="outlined" color="primary" className={classes.tabBtn}>
          Reset
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  listDown: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  listDownInner: {
    fontSize: "12px",
    fontWeight: "300",
    color: "#8e8e8e",
  },
  searchButtonTab: {
    marginLeft: "auto",
  },
  tabContainer: {
    display: "flex",
    margin: 0,
    paddingRight: 0,
  },
  tabBtn: {
    ...theme.typography.tabButton,
    marginLeft: "14px",
    borderRadius: "1em",
    height: "2em",
    textTransform: "none",
    marginBottom: "0.5em",
  },
  mobileBtn: {
    marginLeft: "0px",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.14px",
  },
  backIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    color: theme.text.primary,
  },
  dialogBar: {
    display: "flex",
    height: "51px",
    padding: "5px",
  },
  dialogBarTitle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontSize: "0.9em",
    color: theme.text.primary,
    padding: "3px",
  },
  btnReset: {
    marginLeft: "auto",
    fontSize: "0.9em",
    color: theme.text.primary,
    letterSpacing: "0.14px",
    fontWeight: 500,
  },
  activeSoldBtn: {
    fontWeight: "normal",
    fontSize: "1rem",
  },
}))

export default SearchBar
