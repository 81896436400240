import React, { useState } from "react"
import OptionSingleList from "./options/optionSingleList"
import OptionDualList from "./options/optionDualList"
import OptionMultiSelectList from "./options/optionMultiSelectList"
import { Button, Dialog, Typography, Hidden, Grid } from "@material-ui/core"
import Icon from "@material-ui/core/Icon"
import CommonDB, {
  typeOptions,
  priceOptions,
  sqftOptions,
  bedBathOption,
  yearOptions,
  frontageOptions,
  levelOptions,
  restrictionOptions,
  featureOptions,
  dayOptions,
  parkingOptions,
  keywordOptions,
} from "../../constants/db"

import { OptionGroupedList } from "./options/optionGroupedList"
import { OptionSingleCheckBox } from "./options/optionSingleCheckBox"
import ColoredLine from "../ColoredLine"
import { makeStyles } from "@material-ui/core/styles"

const SearchFilter = props => {
  const classes = useStyles()
  const {
    mapState,
    handleChangeFilter,
    closeFilter,
    openMobFilter,
    filter,
  } = props
  const yearOptions = CommonDB.getInstance().getYearOptions()
  const acreOptions = CommonDB.getInstance().getAcreOptions()

  const handleClose = () => {
    closeFilter(false)
  }

  const DrawLine = () => {
    return (
      <Hidden mdUp>
        <Grid container xs={12}>
          <div
            style={{
              height: "1px",
              backgroundColor: "#eeeeee",
              width: "100%",
            }}
          ></div>
        </Grid>
      </Hidden>
    )
  }

  const SearchFilterDialog = () => (
    <Hidden mdUp>
      <Dialog fullScreen open={openMobFilter} onClose={handleClose}>
        <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
          <div className={classes.dialogBar}>
            <Button className={classes.backIcon} onClick={handleClose}>
              {<Icon style={{ fontSize: "24px" }}>arrow_back</Icon>}
            </Button>
            <Typography className={classes.dialogBarTitle}>
              Refine Search
            </Typography>
            <Button className={classes.btnReset}>RESET</Button>
          </div>
          <ColoredLine />
        </div>
        <Options />
        <div className={classes.mobileFilterFooter}>
          <Button
            variant="contained"
            color="primary"
            className={classes.btnSearch}
          >
            SEARCH
          </Button>
        </div>
      </Dialog>
    </Hidden>
  )

  const Options = () => (
    <div>
      {/* House TYPES */}
      <OptionMultiSelectList
        values={typeOptions}
        name="types"
        value={mapState.types.length > 0 ? mapState.types.split(",") : []}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Types: house, townhouse, apartment",
          prefix: "Types: ",
          postfix: "",
          mobTitle: "Property Type",
          mobIcon: "home",
        }}
      />
      <DrawLine />
      {/* House PRICES */}
      <OptionDualList
        values={priceOptions}
        nameFirst="price_from"
        nameSecond="price_to"
        valueFrom={mapState.price_from}
        valueTo={mapState.price_to}
        handleChangeFilter={handleChangeFilter}
        display={{
          default_1: "Price $50,000",
          default_2: "to $20,000,000",
          prefix_1: "Price $",
          postfix_1: "",
          prefix_2: "to $",
          postfix_2: "",
          mobTitle: "Price",
          mobIcon: "monetization_on",
        }}
      />
      <DrawLine />
      {/* BED OPTIONS */}
      <OptionSingleList
        values={bedBathOption}
        name="beds"
        value={mapState.beds}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Bed 0+",
          prefix: "Beds ",
          postfix: "",
          mobTitle: "Beds",
          mobIcon: "hotel",
        }}
      />
      <DrawLine />
      {/* BATH OPTIONS */}
      <OptionSingleList
        name="baths"
        values={bedBathOption}
        value={mapState.baths}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Bath 0+",
          prefix: "Baths ",
          postfix: "",
          mobTitle: "Baths",
          mobIcon: "bathtub",
        }}
      />
      <DrawLine />
      {/* KITCHEN OPTIONS */}
      <OptionSingleList
        name="kitchens"
        values={bedBathOption}
        value={mapState.kitchens}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Kitchens 0+",
          prefix: "Kitchens ",
          postfix: "",
          mobTitle: "Kitchens",
          mobIcon: "kitchen",
        }}
      />
      <DrawLine />
      {/* SQUARE FEET OPTIONS */}
      <OptionDualList
        values={sqftOptions}
        nameFirst="sqft_from"
        nameSecond="sqft_to"
        valueFrom={mapState.sqft_from}
        valueTo={mapState.sqft_to}
        handleChangeFilter={handleChangeFilter}
        display={{
          default_1: "Square Feet 0",
          default_2: "to 10,000+",
          prefix_1: "Square Feet ",
          postfix_1: "",
          prefix_2: "to ",
          postfix_2: "",
          mobTitle: "Square Feet",
          mobIcon: "dashboard",
        }}
      />
      <DrawLine />
      {/* BUILT BETWEEN OPTIONS */}
      <OptionDualList
        values={yearOptions}
        // values={CommonDB.getInstance().getYearOptions()}
        nameFirst="built_from"
        nameSecond="built_to"
        valueFrom={mapState.built_from}
        valueTo={mapState.built_to}
        handleChangeFilter={handleChangeFilter}
        display={{
          default_1: "Built Between 1900",
          default_2: "and 2019",
          prefix_1: "Built Between ",
          postfix_1: "",
          prefix_2: "and ",
          postfix_2: "",
          mobTitle: "Year Built",
          mobIcon: "event",
        }}
      />
      <DrawLine />
      {/* LOT SIZE OPTIONS */}
      <OptionDualList
        // values={acreOptions}
        values={sqftOptions}
        nameFirst="lotsize_from"
        nameSecond="lotsize_to"
        valueFrom={mapState.lotsize_from}
        valueTo={mapState.lotsize_to}
        handleChangeFilter={handleChangeFilter}
        display={{
          default_1: "Lot size 0 sqft",
          default_2: "to 1,000 acres",
          prefix_1: "Lot size ",
          postfix_1: "",
          prefix_2: "to ",
          postfix_2: "",
          mobTitle: "Lot Size",
          mobIcon: "apps",
        }}
      />
      <DrawLine />
      {/* PER SQFT OPTIONS */}
      <OptionDualList
        values={sqftOptions}
        nameFirst="dollarfoot_from"
        nameSecond="dollarfoot_to"
        valueFrom={mapState.dollarfoot_from}
        valueTo={mapState.dollarfoot_to}
        handleChangeFilter={handleChangeFilter}
        display={{
          default_1: "200/sqft",
          default_2: "to +$2,000+/sqft",
          prefix_1: "",
          postfix_1: "/sqft",
          prefix_2: "to ",
          postfix_2: "/sqft",
          mobTitle: "$/sqft",
          mobIcon: "monetization_on",
        }}
      />
      <DrawLine />
      {/* FRONTAGE OPTIONS */}
      <OptionSingleList
        values={frontageOptions}
        name="frontage"
        value={mapState.frontage}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Frontage 0 feet +",
          prefix: "Frontage ",
          postfix: "",
          mobTitle: "Frontage",
          mobIcon: "settings_ethernet",
        }}
      />
      <DrawLine />
      {/* LEVELS OPTIONS */}
      <OptionSingleList
        values={levelOptions}
        name="levels"
        value={mapState.levels}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Levels 1+",
          prefix: "Levels ",
          postfix: "",
          mobTitle: "Levels",
          mobIcon: "layers",
        }}
      />
      <DrawLine />
      {/* RESTRICTIONS OPTIONS */}
      <OptionMultiSelectList
        values={restrictionOptions}
        name="restrictions"
        value={
          mapState.restrictions.length > 0
            ? mapState.restrictions.split(",")
            : []
        }
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Restrictions",
          prefix: "Restrctions: ",
          postfix: "",
          mobTitle: "Restrictions",
          mobIcon: "pets",
        }}
      />
      <DrawLine />
      {/* FEATURES OPTIONS */}
      <OptionMultiSelectList
        values={featureOptions}
        name="features"
        value={mapState.features.length > 0 ? mapState.features.split(",") : []}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Features",
          prefix: "Features: ",
          postfix: "",
          mobTitle: "Features",
          mobIcon: "pool",
        }}
      />
      <DrawLine />
      {/* PARKING OPTIONS */}
      <OptionSingleList
        values={parkingOptions}
        name="parking"
        value={mapState.parking}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Parking 0+",
          prefix: "Parking ",
          postfix: "",
          mobTitle: "Parking",
          mobIcon: "drive_eta",
        }}
      />
      <DrawLine />
      {/* MEDIA OPTIONS */}
      <OptionMultiSelectList
        values={featureOptions}
        name="media"
        value={mapState.media.length > 0 ? mapState.media.split(",") : []}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Media",
          prefix: "Media: ",
          postfix: "",
          mobTitle: "Media",
          mobIcon: "photo_camera",
        }}
      />
      <DrawLine />
      {/* DOM OPTIONS */}
      <OptionSingleList
        values={dayOptions}
        name="dom"
        value={mapState.dom}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "DOM 24 Hours or less",
          prefix: "DOM ",
          postfix: " days or less",
          mobTitle: "DOM",
          mobIcon: "date_range",
        }}
      />
      <DrawLine />
      {/* PRICE REDUCED OPTIONS */}
      <OptionSingleList
        values={dayOptions}
        name="price_reduced"
        value={mapState.price_reduced}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Price reduced",
          prefix: "Price reduced in last ",
          postfix: " days",
          mobTitle: "Price Reduced",
          mobIcon: "trending_down",
        }}
      />
      <DrawLine />
      <OptionGroupedList
        values={keywordOptions}
        name="keywords"
        value={mapState.keywords.length > 0 ? mapState.keywords.split(",") : []}
        handleChangeFilter={handleChangeFilter}
        display={{
          default: "Keywords",
          prefix: "Keywords: ",
          postfix: "",
        }}
      />
      {/* <OptionSingleCheckBox name="Open House" mobIcon="meeting_room" />
      <DrawLine />
      <OptionSingleCheckBox name="Floor Plan" mobIcon="playlist_add_check" />
      <DrawLine /> */}
    </div>
  )

  return (
    <div className={filter ? classes.filterWrapper1 : classes.filterWrapper}>
      <SearchFilterDialog />
      <Hidden smDown>
        <Options />
      </Hidden>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  filterWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  filterWrapper1: {
    display: "none",
  },
  backIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    color: theme.text.primary,
  },
  dialogBar: {
    display: "flex",
    height: "51px",
    padding: "5px",
    backgroundColor: "#fff",
  },
  dialogBarTitle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontSize: "0.9em",
    color: theme.text.primary,
    padding: "3px",
  },
  btnReset: {
    marginLeft: "auto",
    fontSize: "0.9em",
    color: theme.text.primary,
    letterSpacing: "0.14px",
    fontWeight: 500,
  },
  mobileFilterFooter: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    backgroundColor: "#fff",
    alignItems: "center",
    minHeight: "60px",
    display: "flex",
    justifyContent: "center",
  },
  btnSearch: {
    width: "90%",
    height: "36px",
    fontSize: "14px",
    letterSpacing: "0.14px",
    fontWeight: 400,
  },
}))

export default SearchFilter
