import React, { Component, createRef } from "react"
import InfoWindow from "./InfoWindow"
import { render } from "react-dom"
import { getMarkerSVG, getShortPrice } from "../utils/mapData"
import CircularProgress from "@material-ui/core/CircularProgress"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import "./mapSearch.css"
class Maps extends Component {
  googleMapRef = React.createRef()
  markers = []

  componentDidMount() {
    var s = document.createElement("script")
    s.type = "text/javascript"
    s.src = `https://maps.google.com/maps/api/js?key=AIzaSyBe_jE1XvuaLT9mHySPF4dLAu3kmQXprB0`
    var x = document.getElementsByTagName("script")[0]
    x.parentNode.insertBefore(s, x)
    s.addEventListener("load", e => {
      this.googleMap = this.createGoogleMap()
      this.createMarker()
      this.setMapOnAll(this.googleMap)

      this.googleMap.addListener("dragend", () => {
        this.getCoords()
      })

      this.googleMap.addListener("zoom_changed", () => {
        this.getCoords()
      })
    })
  }

  getCoords = () => {
    let zoom = this.googleMap.getZoom()
    let ne = this.googleMap.getBounds().getNorthEast()
    let sw = this.googleMap.getBounds().getSouthWest()
    let lat = this.googleMap.getCenter().lat()
    let lng = this.googleMap.getCenter().lng()
    this.props.handleBounds({
      swlat: sw.lat(),
      swlng: sw.lng(),
      nelat: ne.lat(),
      nelng: ne.lng(),
      lat,
      lng,
      zoom: zoom,
    })
  }

  createGoogleMap = () =>
    new window.google.maps.Map(this.googleMapRef.current, {
      center: {
        lat: parseFloat(this.props.latitude),
        lng: parseFloat(this.props.longitude),
      },
      zoom: parseInt(this.props.zoom),
    })

  componentDidUpdate(prevp) {
    if (prevp.data !== this.props.data) {
      this.createMarker()
      this.setMapOnAll(this.googleMap)
    }
  }

  setMapOnAll = map => {
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map)
    }
  }

  deleteMarkers = () => {
    this.clearMarkers()
    this.markers = []
  }

  clearMarkers = () => {
    this.setMapOnAll(null)
  }

  createMarker = () => {
    this.deleteMarkers()
    const { data } = this.props
    return data?.map(markers => {
      return markers.markers.forEach(marker => {
        var mark = new window.google.maps.Marker({
          position: {
            lat: parseFloat(marker.lat),
            lng: parseFloat(marker.lng),
          },
          map: this.googleMap,
          icon: {
            url: getMarkerSVG(
              marker.listing.status,
              false,
              marker.listing.status === "Sold"
                ? marker.listing.soldpricenum
                : marker.listing.listpricenum,
              marker.count
            ),
            scaledSize: new window.google.maps.Size(40, 40),
          },
        })
        mark.addListener("click", e => {
          this.createInfoWindow(e, this.googleMap, marker)
        })
        this.markers.push(mark)
      })
    })
  }

  createInfoWindow = (e, map, marker) => {
    const infoWindow = new window.google.maps.InfoWindow({
      content: '<div id="infoWindow" />',
      position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    })
    infoWindow.addListener("domready", e => {
      render(
        <InfoWindow
          marker={marker}
          handleFav={this.props.handleFav}
          fav={this.props.fav}
        />,
        document.getElementById("infoWindow")
      )
    })
    infoWindow.open(map)
  }

  render() {
    const { spinner } = this.props

    return (
      <div className="mainWrapper">
        <div
          id="google-map"
          ref={this.googleMapRef}
          style={{ height: 700 }}
        ></div>
        {spinner ? (
          <div className="childCom">
            <CircularProgress disableShrink size={28} />
          </div>
        ) : (
          <div className="childCom">
            <div className="childInner">
              <ErrorOutlineIcon disableShrink />
              <p>
                Too many homes for us to display on the map. Zoom in, or use
                filters to narrow your search.
              </p>
            </div>
            <ErrorOutlineIcon disableShrink />
          </div>
        )}
      </div>
    )
  }
}

export default Maps
