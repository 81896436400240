import React, { useState } from "react"
import {
  makeStyles,
  MenuItem,
  Typography,
  FormControl,
  Select,
  Grid,
  Icon,
} from "@material-ui/core"
import { storeMapState } from "../../../selector"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

const OptionDualList = ({
  values,
  display,
  nameFirst,
  nameSecond,
  valueFrom,
  valueTo,
  handleChangeFilter,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  const [dualList_1_Value, setDualList_1_Value] = useState("")
  const [dualList_2_Value, setDualList_2_Value] = useState("")

  //Below method is handling dual list showing in single button
  const handleDualList_1 = event => {
    const { name, value } = event.target
    setDualList_1_Value(value)
  }
  const handleDualList_2 = event => {
    const { name, value } = event.target
    setDualList_2_Value(value)
  }

  const Form1 = ({ padding }) => (
    <FormControl>
      <Select
        style={{ padding: padding }}
        disableUnderline
        value={valueFrom}
        onChange={e => handleChangeFilter(e)}
        name={nameFirst}
        displayEmpty
        autoWidth="true"
        className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        renderValue={selected => {
          if (selected.length === 0) {
            return (
              <Typography className={classes.btnText}>
                {display.default_1}
              </Typography>
            )
          }
          return (
            <Typography className={classes.btnText}>
              {`${display.prefix_1}` + selected + `${display.postfix_1}`}
            </Typography>
          )
        }}
      >
        {values.map((name, index) => (
          <MenuItem key={index} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const Form2 = ({ padding }) => (
    <FormControl>
      <Select
        style={{ padding: padding }}
        disableUnderline
        value={valueTo}
        onChange={e => handleChangeFilter(e)}
        name={nameSecond}
        displayEmpty
        autoWidth="true"
        className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        renderValue={selected => {
          if (selected.length === 0) {
            return (
              <Typography className={classes.btnText}>
                {display.default_2}
              </Typography>
            )
          }
          return (
            <Typography className={classes.btnText}>
              {`${display.prefix_2}` + selected + `${display.postfix_2}`}
            </Typography>
          )
        }}
      >
        {values.map((name, index) => (
          <MenuItem key={index} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const WebContent = () => (
    <div className={classes.btnContainer}>
      <Form1 />
      <Form2 />
    </div>
  )

  const MobileContent = () => (
    <div
      style={{
        height: "68px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={2}
          style={{
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Icon style={{ fontSize: "24px", color: "#525c6d" }}>
            {display.mobIcon}
          </Icon>
        </Grid>
        <Grid item xs={10} style={{ margin: "auto" }}>
          <div style={{ display: "block" }}>
            <Typography className={classes.title}>
              {display.mobTitle}
            </Typography>
            <Form1 padding="0" />
            <Form2 padding="0" />
          </div>
        </Grid>
      </Grid>
    </div>
  )

  return matches ? <MobileContent /> : <WebContent />
}

const useStyles = makeStyles(theme => ({
  select: {
    textTransform: "none",
    fontSize: "14px",
    color: "#333",
    height: "30px",
    fill: "green",
    paddingLeft: "0.4em",
  },
  btnContainer: {
    border: "1px solid #e4e4e4",
    borderRadius: "5px",
    display: "inline-block",
    margin: "0.4em",
    padding: "0.2em",
  },
  btnText: {
    textTransform: "none",
    fontSize: "1em",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.95em",
      color: "#515b6e",
    },
  },
  icon: {
    fill: "grey",
  },
  title: {
    fontSize: "14px",
    color: "#a9afba",
  },
}))

export default OptionDualList
