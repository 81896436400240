export const getVisibleGrid = mapState => {
  let _zooms = {
    "1": { lat: 79.36, lng: 90.112 },
    "2": { lat: 39.68, lng: 45.056 },
    "3": { lat: 19.84, lng: 22.528 },
    "4": { lat: 9.92, lng: 11.264 },
    "5": { lat: 4.96, lng: 5.632 },
    "6": { lat: 2.048, lng: 2.816 },
    "7": { lat: 1.024, lng: 1.408 },
    "8": { lat: 0.512, lng: 0.704 },
    "9": { lat: 0.256, lng: 0.352 },
    "10": { lat: 0.128, lng: 0.176 },
    "11": { lat: 0.064, lng: 0.088 },
    "12": { lat: 0.032, lng: 0.044 },
    "13": { lat: 0.016, lng: 0.022 },
    "14": { lat: 0.008, lng: 0.011 },
    "15": { lat: 0.004, lng: 0.0056 },
    "16": { lat: 0.002, lng: 0.0028 },
    "17": { lat: 0.001, lng: 0.0014 },
  }
  let _swlat = mapState.swlat
  let _swlng = mapState.swlng
  let _nelat = mapState.nelat
  let _nelng = mapState.nelng
  let _zoom = mapState.zoom

  let newGrid = []

  let zoom = _zoom.toString()
  let originNorthEastLat =
    Math.round(_nelat / _zooms[zoom]["lat"]) * _zooms[zoom]["lat"]
  let originSouthWestLng =
    Math.round(_swlng / _zooms[zoom]["lng"]) * _zooms[zoom]["lng"]
  let tilesAcross = Math.ceil(
    (_nelng - originSouthWestLng) / _zooms[zoom]["lng"]
  )
  let tilesDown = Math.ceil((originNorthEastLat - _swlat) / _zooms[zoom]["lat"])

  for (var i = 0; i < tilesAcross; i++) {
    for (var j = 0; j < tilesDown; j++) {
      // tile bounds
      let swlat = originNorthEastLat - (j + 1) * _zooms[zoom]["lat"]
      let swlng = originSouthWestLng + i * _zooms[zoom]["lng"]

      let nelat = originNorthEastLat - j * _zooms[zoom]["lat"]
      let nelng = originSouthWestLng + (i + 1) * _zooms[zoom]["lng"]

      // center of tile
      let lat = nelat - (nelat - swlat) / 2
      let lng = swlng + (nelng - swlng) / 2
      // grid id
      let latcell = Math.floor(lat / _zooms[zoom]["lat"])
      let lngcell = Math.floor(lng / _zooms[zoom]["lng"])

      let gridcell = (
        latcell.toString(20) +
        "-" +
        lngcell.toString(20)
      ).replace(/--/g, "-")

      newGrid.push(gridcell)
    }
  }
  return newGrid.join(".")
}
