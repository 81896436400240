import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "../components/Theme"
// import Login fro./logingin"
import Layout from "../components/Layout/layout"
import FilterComponent from "../components/Filters/filterComponent"

const IndexPage = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Layout>
        {/* <h1>Landing</h1>
        <p>
          The Landing Page is open to everyone, even though the user isn't
          signed in.
        </p> */}
        {/* <SearchFilter /> */}
        <FilterComponent />
      </Layout>
    </ThemeProvider>
  </React.StrictMode>
)

export default IndexPage
