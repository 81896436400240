import React from "react"
import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  Typography,
  Box,
  Grid,
  Icon,
} from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

export const OptionSingleCheckBox = ({ name, mobIcon }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  const [state, setState] = React.useState({
    checked: false,
  })

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const Form = ({ padding }) => (
    <FormControlLabel
      style={{ padding: padding }}
      control={
        <Checkbox
          checked={state.checked}
          onChange={handleChange}
          name="checked"
          color="primary"
        />
      }
      label={
        <Box component="div" fontSize={14}>
          {name}
        </Box>
      }
      className={classes.label}
    />
  )
  const WebContent = () => (
    <div className={classes.container}>
      <Form />
    </div>
  )

  const MobileContent = () => (
    <div
      style={{
        height: "68px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={2}
          style={{
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Icon style={{ fontSize: "24px", color: "#525c6d" }}>{mobIcon}</Icon>
        </Grid>
        <Grid item xs={10} style={{ margin: "auto" }}>
          <div style={{ display: "block" }}>
            <Typography className={classes.title}>{name}</Typography>
            <Form padding="0" width="95%" />
          </div>
        </Grid>
      </Grid>
    </div>
  )

  return matches ? <MobileContent /> : <WebContent />
}

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: "14px",
    color: "#333",
    height: "30px",
    paddingLeft: "0.5em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.95em",
      color: "#515b6e",
    },
  },
  container: {
    fontSize: "14px",
    border: "1px solid #e4e4e4",
    borderRadius: "5px",
    display: "inline-block",
    margin: "0.5em",
    padding: "0.2em",
  },
  title: {
    fontSize: "14px",
    color: "#a9afba",
  },
}))
