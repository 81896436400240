import React, { useState } from "react"
import {
  makeStyles,
  MenuItem,
  Typography,
  FormControl,
  Select,
  Grid,
  Icon,
} from "@material-ui/core"
import { storeMapState } from "../../../selector"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

const OptionSingleList = ({
  values,
  display,
  name,
  value,
  handleChangeFilter,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  const Form = ({ padding, width }) => (
    <FormControl style={{ width: width }}>
      <Select
        style={{ padding: padding }}
        disableUnderline
        value={value}
        name={name}
        onChange={e => handleChangeFilter(e)}
        displayEmpty
        className={classes.select}
        autoWidth="true"
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        renderValue={selected => {
          if (selected.length === 0) {
            return (
              <Typography className={classes.btnText}>
                {display.default}
              </Typography>
            )
          }
          return (
            <Typography className={classes.btnText}>
              {`${display.prefix}` + selected + `${display.postfix}`}
            </Typography>
          )
        }}
      >
        {values.map(name => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  const WebContent = () => (
    <div className={classes.btnContainer}>
      <Form />
    </div>
  )

  const MobileContent = () => (
    <div
      style={{
        height: "68px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={2}
          style={{
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Icon style={{ fontSize: "24px", color: "#525c6d" }}>
            {display.mobIcon}
          </Icon>
        </Grid>
        <Grid
          item
          xs={10}
          style={{
            margin: "auto",
          }}
        >
          <div style={{ display: "block" }}>
            <Typography className={classes.title}>
              {display.mobTitle}
            </Typography>
            <Form padding="0" width="95%" />
          </div>
        </Grid>
      </Grid>
    </div>
  )

  return matches ? <MobileContent /> : <WebContent />
}

const useStyles = makeStyles(theme => ({
  select: {
    textTransform: "none",
    fontSize: "14px",
    color: "#333",
    height: "30px",
    fill: "green",
    paddingLeft: "0.4em",
  },
  btnContainer: {
    border: "1px solid #e4e4e4",
    borderRadius: "5px",
    display: "inline-block",
    margin: "0.4em",
    padding: "0.2em",
  },
  btnText: {
    textTransform: "none",
    fontSize: "1em",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.95em",
      color: "#515b6e",
    },
  },
  icon: {
    fill: "grey",
  },
  menuItem: {
    height: "2em",
  },
  title: {
    fontSize: "14px",
    color: "#a9afba",
  },
}))

export default OptionSingleList
