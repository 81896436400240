import React, { useState } from "react"
import {
  makeStyles,
  Typography,
  FormControl,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@material-ui/core"

export const OptionGroupedList = ({
  values,
  display,
  name,
  value,
  handleChangeFilter,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.btnContainer}>
      <FormControl>
        <Select
          disableUnderline
          displayEmpty
          multiple
          value={value}
          name={name}
          onChange={e => handleChangeFilter(e)}
          input={<Input />}
          autoWidth="true"
          renderValue={selected => {
            if (selected.length === 0) {
              return (
                <Typography className={classes.btnText}>
                  {display.default}
                </Typography>
              )
            }
            return (
              `${display.prefix}` + selected.join(", ") + `${display.postfix}`
            )
          }}
          className={classes.select}
        >
          {Object.keys(values).map((key, index) =>
            values[key].map((name, index) => (
              <MenuItem
                dense
                className={classes.menuItem}
                key={name}
                value={name}
              >
                <div style={{ display: "block" }}>
                  {index === 0 ? (
                    <Typography className={classes.header}>{key}</Typography>
                  ) : (
                    ""
                  )}
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      color="primary"
                      checked={value.indexOf(name) > -1}
                    />
                    <ListItemText
                      className={classes.listItemText}
                      primary={name}
                    />
                  </div>
                </div>
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  select: {
    textTransform: "none",
    fontSize: "14px",
    color: "#333",
    height: "30px",
    fill: "green",
    paddingLeft: "0.4em",
  },
  btnContainer: {
    border: "1px solid #e4e4e4",
    borderRadius: "5px",
    display: "inline-block",
    margin: "0.4em",
    padding: "0.2em",
  },
  btnText: {
    textTransform: "none",
    fontSize: "1em",
    color: "#333",
  },
  icon: {
    fill: "grey",
  },
  header: {
    fontSize: "12px",
    color: "#9e9e9e",
  },
  listItemText: {
    fontSize: "15px !important",
    color: "#333",
    margin: "auto",
  },
}))
