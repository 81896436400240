import React from "react"
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded"
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded"
import "./mapSearch.css"

function InfoWindow(props) {
  const { marker } = props
  return (
    <div className="realstate_info">
      <div className="img_wrap">
        <img src={marker.listing.image} />
        <a href={marker.listing.url} className="stripe">
          <h4>{marker.listing.status}</h4>
          <p>{marker.listing.sold_date}</p>
        </a>
        {props.fav ? (
          <FavoriteRoundedIcon onClick={() => props.handleFav()} />
        ) : (
          <FavoriteBorderRoundedIcon onClick={() => props.handleFav()} />
        )}
      </div>
      <div className="content">
        <div className="head">
          <h2>
            {marker.listing.status === "Active"
              ? marker.listing.listprice
              : marker.listing.soldprice}
          </h2>
          <span>{marker.listing.sqft}/sqft</span>
        </div>
        <div className="location_wrap">
          <a href="#" className="address">
            {marker.listing.streetaddress}
          </a>
          <a href="#" className="city">
            {marker.listing.city}
          </a>
        </div>

        <span className="uri">
          <b>{marker.listing.beds} bd</b>
          <b>{marker.listing.baths} ba</b>
          <b>{marker.listing.sqft} sqft</b>
          <b>{marker.listing.dom} dom</b>
        </span>
        <span className="title">
          {marker.listing.type}
          {marker.listing.building_name && (
            <>
              <span> @ </span> <a href="#">{marker.listing.building_name}</a>
            </>
          )}
        </span>
        <p>{marker.title}</p>
      </div>
    </div>
  )
}

export default InfoWindow
