import React, { useState } from "react"
import {
  makeStyles,
  MenuItem,
  Typography,
  FormControl,
  Select,
  Input,
  Checkbox,
  ListItemText,
  Grid,
  Icon,
} from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import ColoredLine from "../../ColoredLine"

const OptionMultiSelectList = ({
  values,
  display,
  name,
  handleChangeFilter,
  value,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  return matches ? ( //Mobile content
    <div
      style={{
        height: "68px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={2}
          style={{
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Icon style={{ fontSize: "24px", color: "#525c6d" }}>
            {display.mobIcon}
          </Icon>
        </Grid>
        <Grid item xs={10} style={{ margin: "auto" }}>
          <div
            style={{
              display: "block",
            }}
          >
            <Typography className={classes.title}>
              {display.mobTitle}
            </Typography>
            <FormControl>
              <Select
                style={{ padding: 0 }}
                disableUnderline
                displayEmpty
                multiple
                value={value}
                autoWidth="true"
                name={name}
                onChange={e => handleChangeFilter(e)}
                input={<Input />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return (
                      <Typography className={classes.btnText}>
                        {display.default}
                      </Typography>
                    )
                  }
                  return (
                    <Typography className={classes.btnText}>
                      {`${display.prefix}` +
                        selected.join(", ") +
                        `${display.postfix}`}
                    </Typography>
                  )
                }}
                className={classes.select}
              >
                {values.map(name => (
                  <MenuItem
                    dense
                    className={classes.menuItem}
                    key={name}
                    value={name}
                  >
                    <Checkbox
                      color="primary"
                      checked={value.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    //Web content
    <div className={classes.btnContainer}>
      <FormControl>
        <Select
          style={{ padding: 0 }}
          disableUnderline
          displayEmpty
          multiple
          name={name}
          value={value}
          autoWidth="true"
          onChange={e => handleChangeFilter(e)}
          input={<Input />}
          renderValue={selected => {
            if (selected.length === 0) {
              return (
                <Typography className={classes.btnText}>
                  {display.default}
                </Typography>
              )
            }
            return (
              <Typography className={classes.btnText}>
                {`${display.prefix}` +
                  selected.join(", ") +
                  `${display.postfix}`}
              </Typography>
            )
          }}
          className={classes.select}
        >
          {values.map(name => (
            <MenuItem
              dense
              className={classes.menuItem}
              key={name}
              value={name}
            >
              <Checkbox color="primary" checked={value.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  select: {
    textTransform: "none",
    fontSize: "14px",
    color: "#333",
    height: "30px",
    fill: "green",
    paddingLeft: "0.4em",
  },
  btnContainer: {
    border: "1px solid #e4e4e4",
    borderRadius: "5px",
    display: "inline-block",
    margin: "0.4em",
    padding: "0.2em",
  },
  btnText: {
    textTransform: "none",
    fontSize: "1em",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.95em",
      color: "#515b6e",
    },
  },
  icon: {
    fill: "grey",
  },
  menuItem: {
    height: "2em",
  },
  title: {
    fontSize: "14px",
    color: "#a9afba",
  },
}))

export default OptionMultiSelectList
